/* ==========================================================================
   Sofia marketing site
   Header and top nav
   ========================================================================== */

.header {
  font-size: 16px;
  position: absolute;
  width: 100%;
  color: #fff;
  a {
    color: #fff;
  }
  .wrapper {
    max-width: 1280px;
  }
}
.nav-menu {
  display: flex;
  flex-direction: row;
  height: $header-height;
  margin: 0;
  padding: 0;
  list-style: none;
  & > li {
    display: flex;
    align-items: center;
  }
}
.nav-home {
  flex: 1;
}
.nav-logo {
  display: block;
  width: 240px;
  height: 60px;
  margin-left: -10px;
  margin-top: 30px;
  text-indent: -999em;
  background-image: url('#{$baseurl}/assets/img/orthellius-logo-col.svg');
  background-size: 100%;
  background-repeat: no-repeat;
}
