/* ==========================================================================
   Sofia marketing site
   Buttons
   ========================================================================== */

.btn {
  display: inline-block;
  padding: 10px 30px;
  border: 0;
  border-radius: 3px;
  font-size: $font-size-default;
  font-weight: $font-weight-bold;
  background-color: $imperial-blue;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $navy;
    text-decoration: none;
  }
}
.btn-sm {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: $font-weight-semibold;
}
