/* ==========================================================================
   Sofia marketing site
   Variables
   ========================================================================== */

// Colours
// --------------------------------------------------

// Core colours
$navy          : #002147;
$imperial-blue : #003E74;
$light-grey    : #EBEEEE;
$cool-grey     : #9D9D9D;
$light-blue    : #D4EFFC;
$black         : #000000;

// Supporting colours: Cool
$blue          : #006EAF;
$lime          : #BBCE00;
// Supporting colours: Warm
$orange        : #D24000;

$color-body 		: #fff;
$text-color 		: $navy;
$link-color 		: $imperial-blue;

// Measurements
// --------------------------------------------------

$gutter            : 20px;
$gutter-thin       : 4px;
$gutter-md         : 40px;
$gutter-wide       : 80px;

$header-height         : 100px;
$header-height-mobile  : 80px;

// Screen size breakpoints
// From Bootstrap 3
// --------------------------------------------------
// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
// Medium screen / desktop
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
// Extra big screen sizes not provided by Bootstrap
$screen-xl            : 1400px; // Bootstrap -lg is 1200px
$screen-xl-min        : $screen-xl;
$screen-lg-max        : ($screen-xl-min - 1);

// Typography
// --------------------------------------------------

@font-face {
    font-family: 'Meta';
    src: url('#{$baseurl}/assets/fonts/MetaBold-Italic.woff') format('woff'),
        url('#{$baseurl}/assets/fonts/MetaBold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Meta';
    src: url('#{$baseurl}/assets/fonts/MetaNormal-Italic.woff') format('woff'),
        url('#{$baseurl}/assets/fonts/MetaNormal-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Meta';
    src: url('#{$baseurl}/assets/fonts/MetaNormal-Roman.woff') format('woff'),
        url('#{$baseurl}/assets/fonts/MetaNormal-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Meta';
    src: url('#{$baseurl}/assets/fonts/MetaBold-Roman.woff') format('woff'),
        url('#{$baseurl}/assets/fonts/MetaBold-Roman.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

$font-family-default              : "Meta", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-default           : 18px;
$font-size-default-mobile    : 16px;
$font-size-large             : 40px;
$font-size-large-mobile      : 19px;
$font-size-small             : 14px;

$font-size-h1                : 33px;
$font-size-h1-mobile         : 29px;
$font-size-h2                : 30px;
$font-size-h2-mobile         : 26px;
$font-size-h3                : 24px;
$font-size-h3-mobile         : 20px;
$font-size-h4                : 20px;

$line-height-default   : 1.6;
$line-height-headings  : 1.2;

$font-weight-normal    : 400;
$font-weight-semibold  : 500;
$font-weight-bold      : 600;
