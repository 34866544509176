/* ==========================================================================
   Sofia marketing site
   Typography
   ========================================================================== */

body {
  color: $navy;
  font-family: $font-family-default;
  font-size: $font-size-default;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-default-mobile;
  }
  line-height: $line-height-default;
  // backgrounds in _backgrounds
}
h1,
h2 {
  line-height: $line-height-headings;
}
h1,
.h1 {
  font-size: $font-size-h1;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-h1-mobile;
  }
  font-weight: $font-weight-bold;
}
h2,
.h2 {
  font-size: $font-size-h2;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-h2-mobile;
  }
  font-weight: $font-weight-bold;
}
h3,
.h3 {
  font-size: $font-size-h3;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-h3-mobile;
  }
  font-weight: $font-weight-bold;
}
h4,
.h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-semibold;
}
a {
  color: $link-color;
}
.lead {
  font-size: $font-size-large;
  @media (max-width: $screen-sm-max) {
    font-size: $font-size-large-mobile;
  }
}
.small-print {
  font-size: $font-size-small;
  line-height: 1.4;
}
