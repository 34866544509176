/* ==========================================================================
   Sofia marketing site
   Form elements
   ========================================================================== */

// General form styles
// --------------------------------------------------------------------------
form {
  margin: 0;
}
.form-control {
  padding: 15px;
  border: 2px solid $cool-grey;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
  font-family: $font-family-default;
  font-size: $font-size-default;
  transition: all 0.3s ease;
  &:focus {
    background-color: #fff;
  }
  &::placeholder {
    color: $cool-grey;
  }
}
.horizontal-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  & > * {
    flex: 1 0 auto; // using long form instead of flex: 1 for IE11
  }
  .form-control {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-group + .form-group {
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .btn {
    padding-top: 0;
    padding-bottom: 0;
    // padding:0 is so that buttons are same height as forms in IE11
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 0 1 auto; // using long form instead of flex: 0 for IE11
  }
  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    .form-control,
    .btn {
      border-radius: 6px !important;
    }
  }
}
.vertical-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    // flex: 1;
  }
  .form-group {
    align-self: stretch;
    margin-bottom: $gutter-thin;
  }
  .form-control {
    width: 100%;
  }
}

// Contact page form
// --------------------------------------------------------------------------
