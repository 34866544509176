/* ==========================================================================
   Sofia marketing site
   Master CSS: imports only
   ========================================================================== */

// Propagate jekyll's baseurl variable into the SCSS
// http://talk.jekyllrb.com/t/scss-specific-background-image-link/766
$baseurl: "";

 // Third party CSS
 // --------------------------------------------------
 // Bootstrap 3.3.7
 @import "bootstrap";
 // View source to see what's included

// Sofia CSS
// Imports only
// --------------------------------------------------

@import 'variables';
@import 'typography';
@import 'layout';
@import 'nav';
@import 'home';
@import 'footer';
@import 'buttons';
@import 'icons';
@import 'forms';
@import 'utilities';
