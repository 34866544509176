/* ==========================================================================
   Sofia marketing site
   Homepage styles
   ========================================================================== */

.section-hero {
  font-size: 20px;
  color: #fff;
  a {
    color: #fff;
    font-weight: bold;
  }
}
.hero-home {
  background-image: url(/assets/img/hero-1.png);
  background-position: center top;
  background-size: cover;

  .wrapper {
    max-width: 1280px;
  }
}
.subtitle {
  display: block;
  opacity: .5;
}
.media {
  display: flex;
  @media (max-width: $screen-sm-max) {
       flex-direction: column;
  }

  .media-body {
    padding-right: 15px;
    padding-bottom: 30px;
    h1 {
      margin-top: 0;
      font-weight: normal;
    }
  }

  .media-img {
    @media (max-width: $screen-sm-max) {
      //display: none;
    }
  }
}
.section {
  padding: 75px 0 150px;

  @media (max-width: $screen-sm-max) {
    padding: 25px 0 50px 0;
  }

  background-color: $light-grey;
  h2,
  .lead {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  h2 {
    margin-top: 0;
  }
}
.section.hero-home {
  padding: 150px 0 150px;

  @media (max-width: $screen-sm-max) {
    padding-bottom: 50px;
  }
}

.video-container {
  @media (min-width: $screen-sm-max) {
    width: 700px;
  }
}
