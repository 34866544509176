/* ==========================================================================
   Sofia marketing site
   Footer
   ========================================================================== */

.footer {
    padding: 40px 0;
    background-color: $black;
	color: $light-grey;
}

.footer-grid {
	display: flex;
    @media (max-width: $screen-sm-max) {
       	flex-direction: column;
	}

	.footer-about {
		flex: 1;
	}

	.footer-logo {
		display: block;
		width: 220px;
		height: 50px;
		margin-bottom: 10px;
		text-indent: -999em;
		background-image: url(/assets/img/orthellius-logo-col.svg);
		background-size: 100%;
		background-repeat: no-repeat;
	}

	.footer-contact {
		text-align: right;
		a {
			display: block;
			color: $light-grey;
		}
		@media (max-width: $screen-sm-max) {
			margin-bottom: 20px;
		}
	}
}

.footer-meta {
	display: flex;

    @media (max-width: $screen-sm-max) {
       	flex-direction: column;
	}

	.footer-legal {
		font-size: 14px;
		flex: 1;
	}

	.footer-social {
		margin-top: 50px;

		li {
			list-style: none;
			display: inline-block;
			margin-left: 20px;
			&:first-child { margin-left: 0;}

			a {
				display: block;
				height: 25px;
				text-indent: -999em;
				background-image: url('#{$baseurl}/assets/img/social.svg');
				background-size: 188px;

				&.linkedin {
					width: 25px;
					background-position: 0 0;
					&:hover { background-position: 0 -25px; }
				}

				&.twitter {
					width: 30px;
					background-position: -31px 0;
					&:hover { background-position: -31px -25px; }
				}

				&.soundcloud {
					width: 56px;
					background-position: -131px 0;
					&:hover { background-position: -131px -25px; }
				}

				&.github {
					width: 29px;
					background-position: -63px 0;
					&:hover { background-position: -63px -25px; }
				}

				&.vimeo {
					width: 34px;
					background-position: -94px 0;
					&:hover { background-position: -94px -25px; }
				}
			}
		}
	}
}
