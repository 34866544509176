/* ==========================================================================
   Sofia marketing site
   Icons
   ========================================================================== */

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  width: 32px;
  height: 32px;
}
.icon-sm {
  width: 16px;
  height: 16px;
}
.icon-md {
  width: 24px;
  height: 24px;
}
.icon-lg {
  width: 48px;
  height: 48px;
}
