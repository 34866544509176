/* ==========================================================================
   Sofia marketing site
   Base layout
   ========================================================================== */

html,
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  padding: 0;
}
.wrapper {
  position: relative;
  max-width: 1052px;
  margin: 0 auto;
  padding: 0 30px;
}
