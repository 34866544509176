/* ==========================================================================
   Sofia marketing site
   Utilities
   ========================================================================== */

// Text hide
// Uses Bootstrap _scaffolding .sr-only

.skiplink:focus {
  position: absolute;
  z-index: 20;
  padding: 5px 15px;
  background-color: red;
  color: #fff;
}


.float-right {
  float: right;
  margin: 0 0 15px 40px;
}
.float-left {
  float: left;
  margin: 0 40px 15px 0;
}
// Dropdown arrow/caret
// from Bootstrap _dropdowns.scss
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top:   4px dashed;
  border-top:   4px solid \9; // IE8
  border-right: 4px solid transparent;
  border-left:  4px solid transparent;
  transition: all .3s ease;
}
.caret-lg {
  border-top-width:   8px;
  border-top-width:   8px;
  border-right-width: 8px;
  border-left-width:  8px;
}

// Responsive video embed
// from http://webdesignerwall.com/tutorials/css-elastic-videos
.video-container {
	position: relative;
	padding-bottom: 50%; // 56.25% for 16:9
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hidden-xs {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
